/* @import url("https://fonts.googleapis.com/css?family=Quicksand");
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open Sans Condensed"); */

@font-face {
  font-family: "LeagueGothic-Regular";
  src: url("./assets/fonts/LeagueGothic-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "PassionOne-Regular";
  src: url("./assets/fonts/PassionOne-Regular.ttf");
}

* {
  margin: 0px;
  padding: 0px;
  color: #353535;
}

/*CUSTOMIZING SCROLLBAR*/
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
